@use "sass:meta";
@use "variables" as *;
/* --------------------------------------------------------------------------------------------------------*/
/* Este código importa los estilos CSS de Quill, un editor de texto enriquecido en JavaScript 
   La importación de estos archivos de estilo permitiría usar y personalizar el aspecto de Quill 
   en la aplicación web.

   Quill se usa en Estudio3R Para tener un editor de texto para que el superadmin pueda crear guias y tips */
@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.bubble.css';
@import 'node_modules/quill/dist/quill.snow.css';
@include meta.load-css('../app/core/classes/QuillResizer/QuillResizer.scss');
/* --------------------------------------------------------------------------------------------------------*/
@import 'node_modules/codemirror/theme/material.css';
/* --------------------------------------------------------------------------------------------------------*/
/* Fuentes utilizadas por el sistema                                                                       */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); // Todo el sistema debe usar esta fuente */
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800;900&display=swap'); // Esta fuente se usa para el logo */


/* --------------------------------------------------------------------------------------------------------*/

/* VARIABLES CSS */
:root {
    --azulElectrico: rgb(0, 105, 217);
    --primary: #009BDD;
    --mobile: 480px;
    --tablet: 768px;
    --desktop: 1440px;
    --agrandar: 5px 15px 5px 15px


    --mdc-outlined-text-field-container-height: 36px; /* Reduce altura */
    --mdc-outlined-text-field-container-shape: 6px; /* Bordes redondeados */
    --mdc-outlined-text-field-label-text-size: 14px; /* Tamaño del label */
    --mdc-outlined-text-field-input-text-size: 14px; /* Tamaño del input */
    --mdc-outlined-text-field-outline-width: 1px; /* Grosor del borde */
    --mdc-outlined-text-field-outline-color: #ccc; /* Color del borde */
    --mdc-outlined-text-field-focus-outline-width: 2px; /* Grosor al hacer foco */
    --agrandar: 5px 15px 5px 15px;
    

    /*Sobre escritura de variables de indigo-pink.css*/

    /*Contenedores de inputs*/
    --mat-form-field-container-vertical-padding: 10px; /* Padding dentro del input */
    --mat-form-field-container-text-size: 14px; /* Tamaño del dentro de los inputs (no aplica para inputs de tipo select) */

    /* Estilos que se usan dentro de los buttons */
    --mdc-text-button-label-text-tracking: normal;
    --mdc-text-button-label-text-weight: 450;
    --mdc-text-button-label-text-size: 14px;
    --mat-text-button-horizontal-padding: 16px;
    --mat-select-trigger-text-size: 14px;

    /*Estilos del mat-select*/
    --mdc-outlined-text-field-outline-color: #0000001f; 
    --mat-form-field-container-height: 40px;    
    --mdc-outlined-text-field-label-text-size: 14px;
    --mat-option-label-text-size: 14px;

    /*Estilos mat-select con appareance="fill"*/
    /*--mdc-filled-text-field-container-color: transparent;*/

    /*Estilos de los navs*/
        /* --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38); */

        

}


/* --------------------------------------------------------------------------------------------------------*/
/* ESTILOS DE LOS INPUTS DE ANGULAR MATERIAL */

    .mat-mdc-text-field-wrapper {
        background-color: #fff !important;
        border-radius: 5px !important;
    }
  
    .mat-mdc-form-field-flex {
        min-height: 46px !important;
    }
  
    /*.mat-mdc-form-field-infix {
        display: flex;
        align-items: center; 
        height: 100%;
        min-height: 46px !important;
        padding: 0px !important;
    }*/


    .mat-mdc-text-field-wrapper input {
        height: 100% !important;
        line-height: 1.2 !important;
        font-size: 14px !important;
    }

    //TODO esto rompia los label a nivel general
    // .mat-mdc-floating-label {
    //     top: calc(50%) !important;
    //     font-size: 14px !important;
    // }

    // .mat-mdc-floating-label.mdc-floating-label--float-above {
    //     top: calc(50% + 6px) !important;
    // }

    .mdc-text-field__input::placeholder {
        font-size: 14px !important;
    }

    .mat-mdc-form-field-error {
        margin-left: -1rem !important;
        font-size: 11px !important;
    }



/* FIN DE ESTILOS DE LOS INPUTS DE ANGULAR MATERIAL */
/* --------------------------------------------------------------------------------------------------------*/

/* --------------------------------------------------------------------------------------------------------*/
/* ESTILOS DE LOS CHECKBOX DE ANGULAR MATERIAL */
.mdc-checkbox {
    padding: 0 !important;
    padding-right: 5px !important;
}

.mdc-checkbox__background {
    // background-color: #fff !important;
    top: 0 !important;
    left: 0 !important;
}

.mat-mdc-checkbox .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
    top:-5px !important;
    left:-5px !important;
    bottom:-5px !important;
}

/* FIN DE ESTILOS DE LOS CHECKBOX DE ANGULAR MATERIAL */
/* --------------------------------------------------------------------------------------------------------*/
  
/* --------------------------------------------------------------------------------------------------------*/
/* ESTILOS DE LOS RADIO BUTTONS DE ANGULAR MATERIAL */
.mat-mdc-radio-button .mdc-radio {
    padding: 0 !important;
    padding-right: 5px !important;
}
/* FIN DE ESTILOS DE LOS RADIO BUTTONS DE ANGULAR MATERIAL */
/* --------------------------------------------------------------------------------------------------------*/

/* --------------------------------------------------------------------------------------------------------*/
/* ESTILOS DE LOS TOGGLE BUTTONS DE ANGULAR MATERIAL */

.mat-button-toggle-button > .mat-button-toggle-label-content{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* FIN DE ESTILOS DE LOS TOGGLE BUTTONS DE ANGULAR MATERIAL */
/* --------------------------------------------------------------------------------------------------------*/

/* --------------------------------------------------------------------------------------------------------*/
/* ESTILOS DE LA AGENDA */
.fc-day-today {
    background-color: rgb(0, 155, 221, 0.2) !important;
    .fc-daygrid-day-number {
        color: #464646 !important;
        font-weight: 600 !important;    
    }
}

.fc-daygrid-event {
    /*border: 1px solid #009BDD !important;*/
    border-radius: 2px !important;
}

.fc-event-title {
    /*background-color: white !important;*/
    color: #464646 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}

.fc-scrollgrid-sync-inner {
    color: #464646 !important;
    text-transform: uppercase !important;
    * {
        font-family: 'Montserrat', sans-serif !important;
    }
}

.fc-header-toolbar {
    * {
        font-family: 'Montserrat', sans-serif !important;
    }

    button {
        background-color: white !important;
        color: #464646 !important;
        text-transform: uppercase !important;
        font-weight: 600 !important;
        border: 1px solid rgb(211, 211, 211) !important;
        .fc-icon {
            color: #464646 !important;
        }
    }
    .fc-button-active {
        background-color: rgb(230, 230, 230) !important;
    }
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: none;
}

.fc .fc-button-solid:not(:disabled).fc-button-active:focus,
.fc .fc-button-solid:not(:disabled):active:focus {
    box-shadow: none;
    background-color:var(--azulElectrico)!important;
}

/* --------------------------------------------------------------------------------------------------------*/
/* Ajusta el ancho de la columna de números de línea */
.CodeMirror-linenumbers {
    // padding-right: 5px;
  }

  .CodeMirror-line {
    // margin-left: 5px !important;
  }

  .CodeMirror {
    height: auto;
  }

  
mat-card, .mat-dialog-container{
    box-shadow: none !important;
}

html {
    scroll-behavior: smooth;
    height: 100%;
}

/* BOTONES */
mat-mdc-button, .mat-mdc-button  {
    color: white !important;
    font-size: 14px !important;
    letter-spacing: 0.0px !important;
}

.mat-calendar-header .mat-calendar-controls mat-mdc-button{
    color: black!important;
  }

.white {
    background-color: white !important;
    border: 1px solid rgb(216, 216, 216) !important;
    color: rgb(62, 62, 62) !important;
}

mat-mdc-button.mat-error, .btn-error {
    background-color: rgb(215, 1, 1) !important;
}

mat-mdc-button.mat-dark-blue, .dark-blue {
    background-color: #1172a9 !important;
}

mat-mdc-button.mat-blue, .blue  {
    background-color: #3087c8 !important;
}

mat-mdc-button.mat-red, .red  {
    background-color: #fb4b4b !important;
}

.border-blue{
    border: 2px solid #0F72DC !important;
    color: #0F72DC !important;
    background-color: white !important;
}

mat-mdc-button.mat-blue-sa, .blue-sa {
    background-color: #375cbc !important;
}

mat-mdc-button.mat-light-blue, .light-blue {
    background-color: #4ba4e7 !important;
}

mat-mdc-button.mat-green, .green {
    background-color: #36ac7d !important;
}

.teal {
    background-color: #00707d !important;
}

mat-mdc-button.mat-gray, .gray {
    background-color: #6a6969 !important;
}

mat-mdc-button .black{
    background-color: #4d4f5c !important;
    color: white !important;
}

mat-mdc-button.mat-red, .btn-red {
    background-color: #fb4b4b !important;
}


mat-mdc-button.mat-light-red, .light-red {
    background-color: #ff6969 !important;
}

mat-mdc-button.mat-orange, .orange {
    background-color: #fbc164 !important;
}

buttonmat-mdc-button:disabled, .mat-mdc-button:disabled {
    background-color: #EDF2F6 !important;
    color: #B0B3B6 !important;
    cursor: default !important;
 }

 buttonmat-mdc-button.mat-lightgray, .lightgray {
    background-color: #f6f6f6 !important;
    color: #002746 !important;
 }

::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de scroll */
}

 /* Track */
 ::-webkit-scrollbar-track {
    background: #f0f0f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4caff6;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #028897;
}

@font-face {
    font-family: exo;
    src: url(./../assets/fonts/Exo-Bold.otf) format("opentype");
}

.hrTop {
    border-top: 1px solid #e5e5e5;
}

.error .mat-mdc-snackbar-surface{
    background-color: $snackBar-error !important;
    color: white !important;
}


.error .mat-simple-snackbar-action {
    color: white;
}

.warning .mat-mdc-snackbar-surface{
    background-color: $snackBar-warning !important;
    color: white !important;
}

.warning .mat-simple-snackbar-action {
    color: white;
}

.success .mat-mdc-snackbar-surface{
    background-color: $snackBar-success !important;
    color: white !important;
}

.success .mat-simple-snackbar-action {
    color: white;
}

.detected-changes {
    width: 100%;
}

.perfilLink {
    cursor: pointer;
}

.perfilLink:hover {
    text-decoration: underline;
}

.carrousel-dialog {
    mat-dialog-container{
        .mat-mdc-dialog-surface{
            background-color: transparent;
            box-shadow: none;
        }
    }
    
}

.tomarPedido-dialog .mat-dialog-container {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
}

html {

    body {
        height: 100vh;
        margin: 0;
        background-color: #f0f0f7;
    }

}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.my-snackbar {
    color: white;
    .mat-simple-snackbar-action {
        background-color: rgb(10, 143, 246);
        color: #50aef1 !important;
    }
}

.correct-snackbar {
    color: rgb(255, 255, 255);
    background-color: #3ca665;

    span {
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
    }
}

.incorrect-snackbar {
    color: red;
    background-color: #ffe4e4;

    span {
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
    }
}

.curP {
    cursor: pointer;
}

.curN {
    cursor: none;
}

mat-icon {
    user-select: none;
}

.fontweight {
    font-weight: var(--weight);
}

.margin-top {
    margin-top: var(--margin-top);
}

.hrTop {
    width: 100%;
    height: 1px;
    margin: 1px 0;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $color-azul-3r;
}

b {
    font-weight: 500;
}

.mat-spinner-color::ng-deep circle {
    stroke: #FFFFFF !important;
}

*,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

*::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mat-icon {
    font-family: 'Material Icons' !important;
    overflow: unset!important;
}

p,h1,h2,h3,h4,h5,h6,.mat-h2, .mat-title, 
.mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2,
.mat-body p, .mat-body-1 p, .mat-typography .mat-body p, .mat-typography 
.mat-body-1 p, .mat-typography p {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
}

.titulo,.subtitulo {
    color: #464646;
}

.titulo{    
    font-size: 1.5rem !important;
}

.subtitulo{
    font-size: 1.2rem !important;
}

.cartel-exito{    
    box-shadow: 3px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 3px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)!important;
    .tic-circulo {
        position: absolute;
        top: -30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: green;
        border: 2px solid white;
        .tic {
            position: absolute;
            top: 20%;
            left: 40%;
            transform: rotate(45deg);
            width: 15px;
            height: 30px;
            border-width: 0 4px 4px 0;
            border-style: solid;
            border-color: white;
            border-radius: 3px;
            background-color: transparent;
          }
      }      
      
    h1{
        letter-spacing: 0.05em;
    }
    p{        
        line-height: 1.5;
    }    
}

.loading-blue {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s infinite linear;
}

.contenedor-msg-info{
    border-radius: 0.3125rem;
    border: 1px solid orange;
    background-color: #ffe3af;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    b{
        font-weight: bold;
    }
    mat-icon{
        color:#ffb700;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loader {
    position: relative;
  }
  
  
  .loader::before {
    content: ""; 
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
  
    background-color: #e4e4eb; 
    background-image: linear-gradient(90deg, #e4e4eb, #eaeaf2, #e4e4eb, #e4e4eb);
    background-size: 400% 100%; 
    animation: loadingAnimation 3s infinite;
  
    z-index: 2; 
  }
  
  @keyframes loadingAnimation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 100% 0;
    }
  }

  .input-error {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; /* Esto hace que se expanda a todo el ancho del contenedor */
    display: none; /* Por defecto estará oculto */
    font-size: 11px;
    color: #f44336;
    animation: slideDown 0.3s ease-out; /* Agregamos animación */
    box-sizing: border-box;
    padding-left: 10px;
    b{
        font-weight: 600;
        margin-left: 3px;
        margin-right: 3px;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.bold {
    font-weight: 600;
}

.underline {
    text-decoration: underline;
}

.uppercase{
    text-transform: uppercase;
}

.carrousel-dialog .mat-dialog-container {
    padding: 0 !important;
    /* otros estilos que quieras aplicar al contenedor del diálogo */
}

.no-padding-dialog .mat-dialog-container {
    padding: 0 !important;
  }

mat-dialog-container{
    .mat-mdc-dialog-surface{
        background-color: white;
        padding: 1.5rem !important;
    }
}

/* RESPONSIVE */


/* Mobile */
@media (max-width: 768px) {

    /* En mobile todos los popUp's deben ocupar el 100% de la pantalla*/
    .cdk-overlay-pane{
        max-width: 100%!important;        
    }

    /*Reduzco un poco el padding en mobile para que entre todo bien*/
    mat-dialog-container{
        padding: 1rem!important;
    }
}

.fc-icon-chevron-left::before {
    content: '\e5c4'; /* Unicode de chevron-left (Material Icons) */
    font-family: 'Material Icons';
  }
  .fc-icon-chevron-right::before {
    content: '\e5c8'; /* Unicode de chevron-right (Material Icons) */
    font-family: 'Material Icons';
  }

  .mat-mdc-paginator{
    width: 100%;
  }
  .mat-mdc-option{
    height: 3em;
    min-height: 40px!important;
  }