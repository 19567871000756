
#editor-resizer {
    position: absolute;
    border: 1px dashed #fff;
    background-color: rgba(0, 0, 0, 0.5);
    .handler {
        position: absolute;
        right: -5px;
        bottom: -5px;
        width: 10px;
        height: 10px;
        border: 1px solid #333;
        background-color: rgba(255, 255, 255, 0.8);
        cursor: nwse-resize;
        user-select: none;
    }

    .toolbar {
        position: absolute;
        top: -3em;
        left: 50%;
        padding: 0.5em;
        border: 1px solid #fff;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        transform: translateX(-50%);
        .group {
        display: flex;
        border: 1px solid #aaa;
        border-radius: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        &:not(:first-child) {
            margin-top: 0.5em;
        }
        .btn {
            flex: 1 0 0;
            text-align: center;
            width: 25%;
            padding: 0 0.5rem;
            display: inline-block;
            color: rgba(0, 0, 0, 0.65);
            vertical-align: top;
            line-height: 2;
            user-select: none;
            &.btn-group {
            padding: 0;
            display: inline-flex;
            line-height: 2em;
            .inner-btn {
                flex: 1 0 0;
                font-size: 2em;
                width: 50%;
                cursor: pointer;
                &:first-child {
                border-right: 1px solid #ddd;
                }
                &:active {
                transform: scale(0.8);
                }
            }
            }
            &:not(:last-child) {
            border-right: 1px solid #bbb;
            }
            &:not(.btn-group):active {
            background-color: rgba(0, 0, 0, 0.1);
            }
        }
        
        }
    }

    .last-item {
        margin-right: 5px;
    }

    .showSize {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0.1em;
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        transform: translateX(-50%);
    }

}
