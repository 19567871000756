/*-----------------------*\
|        Variables        |
\*-----------------------*/

$fondo-lindo: #fcf9ff;
$fondo-idPedido: #007582;
$color-boton-deshabilitado: #d8d8d8;
$color-texto-deshabilitado: grey;
$color-azul-3r: #328dd0;
$color-texto-material: #666666;
$color-gris-bordes: rgb(211, 211, 211);
$color-gris-bordes2:rgb(186, 186, 186);

$azul-3r-gradiente: #2b9dd7;
$azul-3r-gradiente-2: #19c8eb;
$azul-3r-gradiente-3: #07f4ff;

$fondo-lindo: #fcf9ff;
$procesandoPago: #11589b;
$aConfirmar: #1368a9;
$sinFecha: rgb(230, 131, 18);
$nuevaFecha: rgb(255, 145, 0);
$snackBar-error: #b51004;
$snackBar-warning: #cf9940;
$snackBar-success: #49b35b; 
$impago: #d60024;
$pagoRechazado: #f35106;
$pagoRechazado-borde: #f87031;
$impago-borde:#ff4b69;
$pagado: #2a9b2a;
$pagado-borde: #1fc11f;
$coordinado: #0a3959;
$recoordinando: #0e4b66;
$coordinado: #14748e;
$finalizado: #007c09;
$cancelado: #ce003b;
$cancelado-borde: #ff064d;
$enEdicion: #083051;
$relevado: #062133;
$relevado-borde:#104f79;
$reclamado: #58348a;
$reclamado-borde: #914bf4;
$precompra: #12c1c7;
$pendiente: #0069D9;
$pendiente-borde:#72B0F2;

//Cuentas
$relevador: #368eec;
$cliente: #3a9985;
$delegado: #717dd3;
$editor: #e4805d;